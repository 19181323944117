<template>
    <div>
        <contact-section></contact-section>
    </div>
</template>
<script>
import ContactSection from '@/components/ContactSection';

export default {
    name:'Contact',
    components: {ContactSection},
}
</script>