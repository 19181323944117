<template>
  <div>
    <div class="container mt-5">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a @click="$router.push({ name: 'main' })">{{ $t('menu.home') }}</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ $t('menu.contact') }}</li>
        </ol>
      </nav>

      <div class="row">
        <div class="col-12 text-center pt-3">
          <h2 class="title pl-1">{{ $t('menu.contact') }}</h2>
        </div>
      </div>
      <section class="container mt-5">
        <!--Contact heading-->
        <div class="row">
          <!--Grid column-->
          <div class="col-sm-12 mb-4 col-md-5">
            <!--Form with header-->
            <div class="card border-primary rounded-0">
              <div class="card-header p-0">
                <div class="bg-primary text-white text-center py-2">
                  <h3 class="text-white">
                    <i class="fa fa-envelope"></i> {{ $t('contact.formTitle') }}
                  </h3>
                </div>
              </div>
              <div class="card-body p-3">
                <div class="form-group">
                  <label class="font-weight-bold"> {{ $t('contact.name') }} </label>
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="name"
                      class="form-control"
                      :placeholder="$t('contact.hname')"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('contact.email') }}</label>
                  <div class="input-group mb-2 mb-sm-0">
                    <input
                      type="email"
                      v-model="email"
                      class="form-control"
                      :placeholder="$t('contact.hemail')"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('contact.cin') }}</label>
                  <div class="input-group mb-2 mb-sm-0">
                    <input
                      type="number"
                      v-model="cin"
                      class="form-control"
                      :placeholder="$t('contact.hcin')"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('contact.phone') }}</label>
                  <div class="input-group mb-2 mb-sm-0">
                    <input
                      type="number"
                      v-model="phone"
                      class="form-control"
                      :placeholder="$t('contact.hphone')"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="font-weight-bold">{{ $t('contact.message') }}</label>
                  <div class="input-group mb-2 mb-sm-0">
                    <textarea
                      v-model="message"
                      class="form-control"
                      :placeholder="$t('contact.hmessage')"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
                <div class="text-center">
                  <input
                    type="submit"
                    @click="envoyer"
                    :value="$t('contact.send')"
                    class="btn btn-primary btn-block rounded-0 py-2"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-sm-12 col-md-7">
            <!--Buttons-->
            <div class="row text-center" v-if="informations">
              <div class="col-md-4">
                <a class="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block"
                  ><i class="fa fa-map-marker"></i
                ></a>
                <p>{{ informations.adress }}</p>
              </div>
              <div class="col-md-4">
                <a class="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block"
                  ><i class="fa fa-phone"></i
                ></a>
                <p>&#x200E;{{ informations.phone }}</p>
              </div>
              <div class="col-md-4">
                <a class="bg-primary px-3 py-2 rounded text-white mb-2 d-inline-block"
                  ><i class="fa fa-envelope"></i
                ></a>
                <p>{{ informations.email }}</p>
              </div>
            </div>
            <div v-else class="m-5">
              <div class="d-flex justify-content-center">
                <div class="spinner-grow text-primary" role="status"></div>
              </div>
            </div>
            <!--Google map-->
            <div class="mb-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102181.2798692526!2d10.304644538131239!3d36.83852430780497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8cb86245005dd08f!2z2KfZhNmF2LnZh9ivINin2YTYqtmI2YbYs9mKINmE2YTYr9ix2KfYs9in2Kog2KfZhNil2LPYqtix2KfYqtmK2KzZitmR2Kk!5e0!3m2!1sar!2stn!4v1629807131352!5m2!1sar!2stn"
                width="100%"
                height="450"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
          <!--Grid column-->
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import apiAxios from '@/services/axios'
import axios from 'axios'

export default {
  data: () => ({
    name: null,
    lastname: null,
    email: null,
    cin: null,
    message: null,
    phone: null,
    informations: {},
    apiUrl: process.env.VUE_APP_API_URL,
  }),
  created() {
    this.getInformations()
  },
  methods: {
    getInformations() {
      apiAxios
        .post('/cordonnees/filter', {
          query: {},
        })
        .then((res) => {
          this.informations = res.data[0]
        })
    },
    openNotification(type, title, desc) {
      this.$notification[type]({
        message: title,
        description: desc,
      })
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    envoyer() {
      if (this.name && this.email  && this.message ) {
        if (this.validateEmail(this.email)) {
          
            axios
              .put(this.apiUrl + '/public/inscriptions', {
                data: {
                  name: this.name,
                  email: this.email,
                  cin: this.cin,
                  message: this.message,
                  phone: this.phone,
                },
              })
              .then((res) => {
                this.openNotification('success', 'Contact', 'Message envoyer avec succès')
              })
              .catch((e) => {
                this.openNotification('error', 'Contact', "Erreur lors d'envoie")
              })
              .finally(() => {
                this.name = null
                this.lastname = null
                this.email = null
                this.cin = null
                this.message = null
              })
          
        } else {
          this.openNotification('error', 'Contact', 'Inserer un email correcte!')
        }
      } else {
        this.openNotification('error', 'Contact', 'Remplir tous les champs!')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 7em;
  text-align: left;
}

a.bg-primary:hover {
  background-color: $secondary !important;
}
</style>